<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="12" lg="3">
          <div class="sticky-block">
            <v-card class="rounded-lg shadow">
              <v-card-title> Filtrer par</v-card-title>
              <v-divider/>
              <form @submit.prevent="[filter.page = 1,fetchData()]">
                <v-card-text class="pa-4">
                  <v-select
                          v-model="filter.recipients"
                          :item-text="'recipient.name'"
                          :item-value="'recipient_account_id'"
                          :items="recipients"
                          :loading="recipientsLoading"
                          chips
                          class="mb-3"
                          clearable
                          deletable-chips
                          dense
                          hide-details
                          label="Destinataires..."
                          multiple
                          outlined
                          prepend-inner-icon="mdi-account"
                          small-chips
                  ></v-select>

                  <v-dialog
                          ref="dialogStartDate"
                          v-model="modalStartDate"
                          persistent
                          width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                              v-model="formFilter.startDate"
                              class="mb-3"
                              clearable
                              dense
                              hide-details
                              label="Date début"
                              outlined
                              prepend-inner-icon="mdi-sort-calendar-descending"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="formFilter.startDate"
                            @input="
                        [(formFilter.endDate = null), (modalStartDate = false)]
                      "
                    >
                    </v-date-picker>
                  </v-dialog>

                  <v-dialog
                          ref="dialogEndDate"
                          v-model="modalEndDate"
                          persistent
                          width="290px"
                          @click="modalEndDate = false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                              v-model="formFilter.endDate"
                              clearable
                              dense
                              hide-details
                              label="Date fin"
                              outlined
                              prepend-inner-icon="mdi-sort-calendar-ascending"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="formFilter.endDate"
                            :min="formFilter.startDate"
                            @input="modalEndDate = false"
                    >
                    </v-date-picker>
                  </v-dialog>

                  <v-radio-group
                          v-model="formFilter.status"
                          hide-details
                          label="Statut"
                  >
                    <v-radio label="Tous" value="all"></v-radio>
                    <v-radio label="En attente" value="warning"></v-radio>
                    <v-radio label="Acceptée" value="accepted"></v-radio>
                    <v-radio label="Réfusée" value="refused"></v-radio>
                  </v-radio-group>

                  <v-range-slider
                          v-if="!rangeLoading"
                          v-model="filter.range"
                          :max="max"
                          :min="0"
                          class="align-center mt-5 pt-5"
                          hide-details
                          thumb-label="always"
                  >
                    <template v-slot:thumb-label="item">
                      <v-btn
                              class="primary mt-4"
                              elevation="0"
                              small
                              style="font-size: 10px"
                      >
                        {{ CurrencyFormatting(item.value) }} DZ
                      </v-btn>
                    </template>

                  </v-range-slider>
                  <v-progress-circular v-else
                                       class="mt-4"
                                       color="amber"
                                       indeterminate
                                       style="margin: auto;"
                  ></v-progress-circular>
                </v-card-text>

                <v-card-actions>
                  <v-spacer/>
                  <v-btn color="primary" depressed text type="submit">
                    <v-icon left>mdi-magnify</v-icon>
                    Recherche
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card>
          </div>
        </v-col>

        <v-col cols="12" lg="9">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <v-text-field
                      v-model="filter.keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line
              />

              <v-btn
                      v-if="transactions && transactions.data.length > 0"
                      :loading="exportLoading"
                      class="primary text-none ml-3"
                      depressed
                      rounded
                      @click="exportExcel()"
              >
                <v-icon class="mr-2">mdi mdi-microsoft-excel</v-icon>
                Exporter
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>

              <div v-else>
                <div v-if="!isLoading && transactions.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Destinataire</th>
                        <th>Date</th>
                        <th>Montant</th>
                        <th>Etat</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, i) in transactions.data" :key="i">
                        <td class="text-no-wrap font-weight-medium">
                          <v-avatar size="35">
                            <v-img :src="require('@/assets/avatar.png')"/>
                          </v-avatar>
                          {{ item.recipient.name }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon
                                  :style="{ marginTop: '-3px' }"
                                  color="gifty"
                                  small
                          >mdi-clock-outline
                          </v-icon>
                          {{ item.created_at }}
                        </td>

                        <td class="text-no-wrap">
                            <span class="gifty--text font-weight-medium">
                              {{ CurrencyFormatting(item.amount) }} DZD
                            </span>
                        </td>

                        <td>
                          <v-chip
                                  :color="setStatusColor(item.state)"
                                  dark
                                  label
                                  small
                          >
                            {{ setStatus(item.state) }}
                          </v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider/>

                  <div class="d-flex justify-space-between pl-4 pr-4 pt-6">
                    <div :style="{ width: '120px' }">
                      <v-select v-model="filter.size"
                                :items="[10, 20, 30, 50,100]"
                                dense
                                label="Ligne par page"
                                outlined
                                @change="onPageChange"
                      ></v-select>
                    </div>

                    <v-spacer/>

                    <v-pagination
                            v-if="transactions.total > 15"
                            v-model="filter.page"
                            :length="Math.ceil(transactions.total / filter.size)"
                            circle
                            total-visible="6"
                            @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>

                <div v-else class="text-center">
                  <v-avatar size="200" tile>
                    <v-img :src="require('@/assets/no-transaction.svg')"
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            exportLoading: false,
            rangeLoading: true,
            recipientsLoading: false,

            recipients: [],

            set_paginate: 10,

            dialogSms: false,
            uploadDialog: false,
            updateNameDialog: false,
            btnUpdateLoading: false,
            uploadErrors: [],
            pagination: {
                current: 1,
                total: 0,
            },
            uploadForm: {
                excel_file: "",
                amount: "",
            },

            updateNameForm: {
                id: 0,
                amout: 0,
            },

            formFilter: {
                status: "all",
            },
            updateNameErrors: {},
            btnUploadLoading: false,
            ischeckLoading: false,
            modalStartDate: false,
            modalEndDate: false,
            max: 0,
            filter: {
                salepoint_type_ids: [],

                selectedCommunes: [],
                selectedWilayas: [],
                range: [0, 10000],
                has_gifty: "all",
                period: [],
                startDate: "",
                endDate: "",
                keyword: "",
                size: 10,
                page: 1,
                recipients: [],
            },
        };
    },
    methods: {
        fetchData() {
            this.exportLoading = false;
            this.filter.keyword = this.keyword;
            this.filter.startDate = this.formFilter.startDate;
            this.filter.endDate = this.formFilter.endDate;
            this.filter.status = this.formFilter.status;
            this.$store.dispatch("fetchTransactions", {
                filter: this.filter,
            });
        },
        setStatusColor(status) {
            switch (status) {
                case "pending":
                    return "warning ";
                case "verification":
                    return "warning ";
                case "refused":
                    return "error";
                case "accepted":
                    return "success ";
            }
        },
        setStatus(status) {
            switch (status) {
                case "pending":
                    return "En attente";
                case "verification":
                    return "En verification";
                case "refused":
                    return "Refusée";
                case "accepted":
                    return "Acceptée";
            }
        },

        onPageChange() {
            this.fetchData();
        },

        filterRegion() {
            var numeric_array = [];
            for (var item in this.filter.selectedWilayas) {
                numeric_array.push(this.filter.selectedWilayas[item]);
            }
            console.log(numeric_array);
            this.$store.dispatch("fetchCommunes", {
                filter: numeric_array,
            });
        },
        resetFilter() {
            this.keyword = "";

            this.filter = {
                selectedCommunes: [],
                selectedWilayas: [],
                has_gifty: "all",
                period: [],
                startDate: "",
                endDate: "",
                keyword: "",
                size: 10,
            };
        },

        getRange() {
            this.rangeLoading = true;
            HTTP.get("refill/range")
                .then((res) => {
                    this.max = res.data.max;
                    this.filter.range = [res.data.min, res.data.max];
                    this.rangeLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.rangeLoading = false;
                });
        },
        getRecepients() {
            this.recipientsLoading = true;
            HTTP.get("/refill/recipients")
                .then((res) => {
                    this.recipients = res.data;
                    this.recipientsLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.recipientsLoading = false;
                });
        },
        exportExcel() {

            let baseUrl = process.env.VUE_APP_FILE_URL;
            let token = this.$store.state.user.access_token;
            let userId = this.$store.state.user.user.id;

            let url =
                baseUrl +
                "/api/refill/sent/export?token=" +
                token +
                "&user_id=" +
                userId;

            url += "&filter=" + encodeURIComponent(JSON.stringify(this.filter));

            // window.location.href = url;
            this.downloadFile(url)
        },

        downloadFile(urlToSend) {
            this.exportLoading = true;
            var req = new XMLHttpRequest();
            req.open("GET", urlToSend, true);
            req.responseType = "blob";
            req.onload = (event) => {
                var blob = req.response;
                var fileName = "Transactions"; //if you have the fileName header available
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                this.exportLoading = false;
            };

            req.send();
        },
    },
    computed: {
        isLoading() {
            return this.$store.getters.getLoading;
        },

        salepoints() {
            return this.$store.getters.getSalepoints;
        },
        wilayas() {
            return this.$store.getters.getWilayas;
        },

        communes() {
            return this.$store.getters.getCommunes;
        },

        database() {
            return this.$store.getters.getDatabase;
        },

        transactions() {
            return this.$store.getters.getTransactions;
        },

        user() {
            return this.$store.getters.getUser;
        },
        messages() {
            return 0;
        },
        keyword() {
            return this.filter.keyword;
        },
    },
    watch: {
        keyword() {
            this.fetchData();
        },
    },

    mounted() {
        this.getRange();
        this.fetchData();
        this.getRecepients();
        if (this.wilayas.length === 0) this.$store.dispatch("fetchwilayas");
    },
};
</script>